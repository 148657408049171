import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">By</span>Ishøj
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">E-mail mig</span>{" "}
        <a href="mailto:sego_turan81@hotmail.com">sego_turan81@hotmail.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">Ring til mig</span>{" "}
        <a href="Tel: +45 2840 2874">+45 2840 2874</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
